import React from "react"
import Layout from "components/layout"
import usePageData from "hooks/queryContact.vi"
import ViewContact from "components/ViewContact";

const ContactPage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewContact pageData={pageData} />
    </Layout>
  )
}

export default ContactPage
